button:not(.unstyled), .button {
  background: var(--button-background);
  border: 4px solid black;
  color: black;
  padding-inline: var(--size-4);
  padding-block: var(--size-1);
  border-radius: 2px;
  box-shadow: 8px 8px 0 -2px black;
  font-family: inherit;
  font-weight: bold;
  font-size: unset;
  line-height: inherit;
  display: inline-block;
  text-decoration: none;
  transition: transform 70ms;
  cursor: pointer;
  -webkit-tap-highlight-color: transparent;

  &:hover {
    color: black;
    box-shadow: 10px 10px 0 -2px black;
    transform: translate(-2px, -2px);
  }
  &:active {
    color: black;
    box-shadow: 7px 7px 0 -2px black;
    transform: translate(1px, 1px);
  }

  &.is-spaced {
    margin-inline: var(--size-2);

    &:last-of-type {
      margin-inline-end: 0;
    }
  }

  &.is-red {
    background: var(--red-background);
    color: white;
  }

  &.is-purple {
    background: var(--purple-background);
    color: white;
  }

  &.is-green {
    background: var(--action-color);
    color: white;
  }

  &.gigantor {
    font-size: clamp(1rem,4vw,1.4rem);
  }
}

.buttons-list {
  margin-block-start: var(--size-8);
  margin-block-end: var(--size-9);

  & > * {
    margin-block-end: calc(var(--size-7) + var(--size-2));
  }

  &.is-colorful {
    & > :nth-of-type(3n-2) {
      transform: rotate(-0.5deg);
    }

    & > :nth-of-type(3n-1) {
      & > :is(button, .button) {
        background: var(--purple-background);
        color: white;
      }
      transform: rotate(2.5deg);
    }
    & > :nth-of-type(3n) {
      & > :is(button, .button) {
        background: var(--red-background);
        color: white;
      }
      transform: rotate(-2.5deg);
    }
  }
}