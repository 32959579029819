body.homepage {
  & h1 {
    font-size: var(--font-size-7);
    margin-block-end: 1em;
    max-width: 19ch;
    margin-inline: auto;

    @media (min-width: 750px) {
      font-size: var(--size-9);
    }
  }
}

body.post main > article {
  & > header > hgroup {
    & > h1 {
      text-align: left;
    }

    & > p {
      margin-block: var(--size-7) var(--size-6);
      text-align: left;
      font-weight: normal;
      font-size: var(--font-size-4);
      line-height: 1.3;
      color: unset;
    }
  }

  & h2 {
    font-size: var(--font-size-4);
  }

  & img.avatar {
    width: 3ch;
    border-radius: 3ch;
    vertical-align: -0.8ch;
    margin-inline-end: 0.5ch;
  }
}
