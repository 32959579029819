body > header {
  margin-inline: var(--size-4);
  margin-block: var(--size-7) var(--size-2);
  text-align: center;
}

body > header img {
  display: inline-block;
  width: 400px;
  max-width: 100%;
}

body > nav ul, body > header nav ul {
  margin-inline: var(--size-1);
  margin-block: var(--size-7) var(--size-8);
  padding: 0;
  list-style-type: none;
  display: flex;
  flex-wrap: wrap;
  justify-content: center;
  gap: var(--size-6);
  font-size: 1.3rem;
  font-weight: bold;
}

body > nav a, body > header nav a {
  text-decoration: none;
}

nav.is-public {
  & li:nth-of-type(odd) {
    transform: rotate(-3deg);
  }
  & li:nth-of-type(even) {
    transform: rotate(2deg);
  }

  & > ul.buttons-list {
    margin-block: var(--size-7) var(--size-8);
    & > * {
      margin-block-end: 0;
    }
  }
}