/* Shoelace */

sl-breadcrumb + article {
  margin-block-start: var(--size-7);
}

sl-breadcrumb {
  --sl-font-weight-semibold: bold;
}

sl-breadcrumb::part(base) {
  justify-content: center;
}

sl-breadcrumb-item::part(base) {
  color: var(--gray-6);
}

sl-drawer::part(title) {
  font-weight: bold;
}

/* Playground Elements */

playground-ide {
  height: 650px;
  margin-block: 2em 3em;
  border-radius: 12px;
  overflow: hidden;
  box-shadow: var(--code-box-shadow);
  margin-inline: -.75em;

  --playground-preview-width: 45%;
  --playground-border: 1px solid var(--aside-border-color);
  --playground-tab-bar-background: var(--aside-background);
  --playground-tab-bar-foreground-color: var(--body-color);
  --playground-preview-toolbar-background: var(--aside-background);
  --playground-preview-toolbar-foreground-color: var(--body-color);
  --playground-highlight-color: var(--action-color);
  --playground-code-font-size: 18px;
  --playground-code-font-family: var(--code-font);
  --playground-code-padding: .6rem;
  --playground-bar-height: 42px;
  --playground-code-background: var(--color-code-background);
  --playground-code-default-color: #272421;
  --playground-code-keyword-color: var(--purple-background);
  --playground-code-atom-color: #272421;
  --playground-code-number-color: #272421;
  --playground-code-def-color: #272421;
  --playground-code-variable-color: #204a87;
  --playground-code-property-color: #f57900;
  --playground-code-operator-color: #272421;
  --playground-code-variable-2-color: #272421;
  --playground-code-variable-3-color: #272421;
  --playground-code-type-color: #204a87;
  --playground-code-comment-color: #8f5902;
  --playground-code-string-color: #4e9a06;
  --playground-code-string-2-color: #272421;
  --playground-code-meta-color: #272421;
  --playground-code-qualifier-color: #272421;
  --playground-code-builtin-color: #272421;
  --playground-code-tag-color: #204a87;
  --playground-code-attribute-color: #8d8400;
  --playground-code-callee-color: #204a87;
}

/*
playground-ide::part(editor) {
  font-weight: 500;
}
*/

playground-ide::part(preview) {
  font-family: var(--body-font);
}

playground-ide::part(tab-bar) {
  border-bottom: 1px solid var(--aside-border-color);
}

playground-ide::part(code-mirror) {
  box-sizing: border-box;
  font-feature-settings: "ss01";
}

@media (min-width: 1175px) {
  playground-ide {
    margin-inline: calc(0em - 6vw);
    --playground-preview-width: 50%;
  }
}

@media (min-width: 1350px) {
  playground-ide {
    margin-inline: calc(0em - 12vw);
  }
}

@media (max-width: 700px) {
  playground-ide {
    height: auto;
    flex-direction: column;
    --playground-preview-width: 100%;
  }

  playground-ide::part(lhs) {
    border-right: none;
  }

  playground-ide::part(editor) {
    height: 350px;
  }

  playground-ide::part(preview) {
    border-radius: 0;
    height: 350px;
    border-top: 1px solid var(--aside-border-color);
  }
}

@media (max-width: 500px) {
  playground-ide {
    --playground-code-font-size: 16px;
  }
}

.editor-toolbar button:not(.unstyled) {
  box-shadow: none;
}
