@import "open-props/open-props.min.css";

:root {
  --body-background: #efede2;
  --body-color: #383731;
  --body-font: "Basier Circle", -apple-system, sans-serif;
  --code-font: mononoki, ui-monospace, monospace;
  --heading-color: #34284e;
  --footer-background: #221D31;
  --strong-color: var(--footer-background);
  --action-color: #194B02;
  --action-dimmed-color: #88af76;
  --aside-background: #f1faec;
  --aside-border-color: #d2e7c9;

  --button-background: #FFA507;
  --red-background: #da270c;
  --purple-background: #781770;

  --color-medium-gray: var(--gray-7);
  --color-code-background: #fffbf5;

  --content-word-spacing: -0.1ch;

  --code-box-shadow: 0px 13px 18px rgba(0, 0, 0, 0.1);

  --subscribe-block-spacing: var(--size-8);

  --sl-font-sans: var(--body-font);
  --sl-color-primary-600: var(--action-color);
  --sl-color-primary-500: var(--button-background);
}
