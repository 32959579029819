@font-face {
  font-family: 'mononoki';
  src: url("../fonts/mononoki-Regular.woff2") format('woff2');
  font-weight: normal;
  font-style: normal;
}

@font-face {
  font-family: 'mononoki';
  src: url("../fonts/mononoki-Italic.woff2") format('woff2');
  font-weight: normal;
  font-style: italic;
}

@font-face {
  font-family: 'mononoki';
  src: url("../fonts/mononoki-Bold.woff2") format('woff2');
  font-weight: bold;
  font-style: normal;
}
