html {
  box-sizing: border-box;
  font-size: 125%;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;

  @media (max-width: 500px) {
    font-size: 110%;
  }
}

*,
*:before,
*:after {
  box-sizing: inherit;
}

body {
  background: var(--body-background);
  color: var(--body-color);
  font-family: var(--body-font);
  margin: 0;
  line-height: 1.6;
  word-spacing: var(--content-word-spacing);
}

a {
  color: var(--action-color);
  text-decoration: underline;
  text-decoration-color: var(--action-dimmed-color);
  text-underline-offset: 0.2ch;
  transition: all 220ms;

  &:hover {
    color: var(--button-background);
    text-decoration-color: rgb(240, 221, 171);
    text-underline-offset: 0.6ch;
  }
}

h1 {
  margin-block: var(--size-3) var(--size-7);
  text-align: center;
  font-weight: bold;
  font-size: var(--font-size-7);
  color: var(--heading-color);
  line-height: 1.1;
  overflow-wrap: break-word;
  word-spacing: var(--content-word-spacing);
}

h1 + h2 {
  margin-block-start: var(--size-7);
}

h2, h3 {
  margin-block: var(--size-8) var(--size-6);
  line-height: 1.3;
  font-weight: bold;
}

h2 {
  font-size: var(--font-size-5);
  word-spacing: var(--content-word-spacing);
}

h3 {
  font-size: var(--font-size-4);
}

h2,
h3,
h4,
h5,
h6 {
  color: var(--heading-color);
  text-align: center;
  overflow-wrap: break-word;
}

strong {
  letter-spacing: 0.02ch;
}

em, strong {
  color: var(--strong-color);
}
em > strong, strong > em {
  font-weight: 500;
}

abbr[title] {
  color: var(--strong-color);
  font-weight: bold;
  cursor: help;
  text-decoration: underline;
  text-decoration-style: dotted;
  text-decoration-color: var(--action-dimmed-color);
  text-underline-offset: 0.2ch;
}

p, ul, ol, dl {
  margin-block-start: 0;
  margin-block-end: var(--size-6);
}

p, blockquote, figure, li {
  overflow-wrap: break-word;
}

*:focus-visible {
  outline: 2px dotted var(--action-dimmed-color);
}

main {
  margin: 2rem auto 4rem;
  max-width: 86ch;
  min-height: calc(100vh - 200px);
  background: white;
  box-shadow: 0 40px 25px 6px rgba(129, 92, 5, 0.1);
  border-radius: 40px;

  @media (max-width: 500px) {
    border-radius: 36px;
  }
}

blockquote {
  border-inline-start: 6px solid var(--gray-7);
  padding-inline-start: var(--size-5);
  margin-inline: 0;
  margin-block: 0 var(--size-6);
}

figure {
  margin-inline: 0;
  margin-block: 0 var(--size-6);
}

dt {
  font-size: var(--font-size-2);
  font-weight: bold;
  color: var(--heading-color);
}

dd + dt {
  margin-block-start: var(--size-3);
}

dd {
  margin-inline-start: 0;
  padding-inline-start: var(--size-5);
  border-inline-start: 6px solid var(--gray-3);
  margin-block-start: var(--size-1);
}

mark {
  box-decoration-break: clone;
  padding: 5px;
  margin-inline: -5px;
  background: linear-gradient(120deg, #fff4b411, #fff4b488, #fff4b477, #fff4b488, #fff4b466, #fff4b411);
  box-shadow: 0px 0px 3px #fff4b455;
}

hr {
  border: none;
  border-top: 6px solid var(--gray-7);
  max-width: 20ch;
  margin: 3rem auto;
}

body > footer {
  text-align: center;
  padding-inline: var(--size-1);
  padding-block: var(--size-9);
  font-size: var(--font-size-3);
  background: var(--footer-background);
  color: var(--body-background);

  --strong-color: white;

  & img {
    vertical-align: middle;
    margin-right: var(--size-3);
  }

  & a {
    color: rgb(240, 221, 171);
    text-decoration: none;
  }

  & small {
    display: block;
    margin-block-start: var(--size-4);
    margin-block-end: -0.15rem;
    font-size: 0.85rem;
    line-height: 2.5;
  }

  @media (max-width: 650px) {
    display: flex;
    flex-direction: column;
    gap: var(--size-3);
    align-items: center;

    & img {
      margin-right: 0;
    }
  }
}

/* Avoid FOUC in Firefox */
template[shadowrootmode] ~ * {
  display: none;
}
