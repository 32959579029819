
notes-drawer {
  > sl-drawer {
    --size: clamp(30rem, 88dvh, 40rem);
  }

  > sl-drawer::part(body) {
    padding-block-start: 0;
  }

  & div[contenteditable="true"] {
    min-height: 12rem;
    max-height: clamp(12rem, 40dvh, 25rem);
    overflow: auto;
  }

  > sl-drawer > p {
    font-size: 80%;
    margin-block-end: 1.2em;
  }

  & p[save-message] {
    float: left;
    color: darkgreen;
    margin-top: var(--size-1);
  }
}

