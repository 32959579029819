main > article {
  & img {
    max-width: 100%;
  }

  & aside {
    margin-block-start: 0;
    margin-block-end: var(--size-6);
    padding: var(--size-4);
    background: var(--aside-background);
    border: 1px solid var(--aside-border-color);
    border-radius: 3px;

    & p:last-of-type {
      margin-block-end: 0;
    }
  }

  & :is(ul, ol) {
    margin-inline-start: 0;
    padding-inline-start: var(--size-5);

    & li {
      margin-block-end: var(--size-2);
    }
  }
}

:is(ul, ol).items {
  list-style-type: none;
  margin-inline: 0;
  padding-inline: 0;
  text-align: center;

  & img[plus] {
    width: 27.5px;
    border-radius: .5ch;
    position: absolute;
    right: 2%;
    bottom: 4%;
    box-shadow: 0px 0px 8px rgba(255,255,255,0.75);
  }
}

/* see _components/button.global.css for more button styles */

figure.avatar {
  float: right;
  width: clamp(120px,27vw,30%);
  margin-inline: var(--size-4) 0;
  margin-block: var(--size-1) var(--size-4);

  & > img {
    border-radius: 100%;
    border: 5px solid var(--gray-7);
    display: block;
    width: 100%;
  }
}

.heading-anchor {
  position: absolute;
  padding-left: var(--size-2);
  opacity: 0;
  transition: opacity 0.3s;
}
:is(h2:hover, h3:hover) .heading-anchor, .heading-anchor:focus {
  opacity: 1;
}

h1 sl-icon {
  font-size: 1.25em;
  vertical-align: -0.2em;
  margin-inline-end: 0.2ch;

  width: 75px;
  height: 75px;

  @media (max-width: 500px) {
    width: 66px;
    height: 66px;
  }
}

h1 sl-icon::part(svg) {
  filter: drop-shadow(0px 5px 5px var(--aside-border-color));
}

responsive-embed {
  display: block;
  position: relative;
  aspect-ratio: 16 / 9;
  
  & iframe {
    background-color: var(--stone-1);
    overflow: hidden;
    border-radius: 1ch;
    box-shadow: 0 8px 12px 4px rgba(129, 92, 5, 0.1);
  }
}

form-field {
  display: block;
  margin-block: var(--size-4);

  & > label {
    display: block;
  }

  &:has([aria-invalid]) {
    & > label {
      color: var(--red-7);
    }
  }
}

form.centered {
  width: fit-content;
  margin-inline: auto;

  & form-button {
    text-align: center;
    display: block;
    margin-block-start: var(--size-7);
  }
}

input:not([type=button]) {
  border: 4px solid black;
  padding: var(--size-2);
  font-family: inherit;
  font-size: inherit;
  --shadow-strength: 7%;
  box-shadow: 0 -2px 5px 0 hsl(220 40% 2%/calc(var(--shadow-strength) + 2%)),0 1px 1px -2px hsl(220 40% 2%/calc(var(--shadow-strength) + 3%)),0 2px 2px -2px hsl(220 40% 2%/calc(var(--shadow-strength) + 3%)),0 5px 5px -2px hsl(220 40% 2%/calc(var(--shadow-strength) + 4%)),0 9px 9px -2px hsl(220 40% 2%/calc(var(--shadow-strength) + 5%)),0 16px 16px -2px hsl(220 40% 2%/calc(var(--shadow-strength) + 6%));
  width: 30ch;
  max-width: 100%;

  &:focus-visible {
    border-color: var(--purple-background);
    outline: none;
  }

  &[aria-invalid] {
    box-shadow: 0 8px 25px -8px var(--red-3);
  }
}

p > sl-icon[name^="square"] {
  margin-inline-end: var(--size-1);
  vertical-align: -.2em;
  font-size: 1.25em;
  color: var(--green-10);
}



.currently-playing {
  --currently-playing-color: #f2f7fa;
  background-color: var(--currently-playing-color);
  outline: 8px solid var(--currently-playing-color);
  box-shadow: 0px 0px 10px 8px var(--currently-playing-color);
  border-radius: 1px;
}

main > article aside .currently-playing {
  --currently-playing-color: white;
}
