.skip-to-content-link {
  background: var(--action-color);
  color: white;
  width: 18ch;
  height: calc(var(--size-7) + var(--size-2));
  left: calc(50% - 9ch);
  padding: var(--size-2) calc(var(--size-2) + var(--size-1));
  position: absolute;
  transform: translateY(calc(0rem - var(--size-9)));
  transition: transform 0.3s;
  top: 0;
  text-align: center;
  z-index: 1;
}

.skip-to-content-link:focus {
  transform: translateY(0%);
}

body.public {
  & main {
    padding-inline: var(--size-6);
    padding-block: var(--size-4) var(--size-5);

    @media (max-width: 500px) {
      padding-inline: var(--size-4);
    }
  }
}
