
account-drawer {
  :is(p, li):last-of-type {
    margin-block-end: 0;
  }

  .button {
    display: block;
  }

  & ul {
    margin: 0 !important;
    padding: 0;
    list-style-type: none;
  }

  & li {
    margin-block-end: var(--size-4);
  }

  & p {
    font-size: var(--font-size-0);
  }
}

