@font-face {
  font-family: "Basier Circle";
  src: url("../fonts/basiercircle-regular-webfont.woff2") format("woff2");
  font-weight: normal;
  font-style: normal;
  font-display: fallback;
}

@font-face {
  font-family: "Basier Circle";
  src: url("../fonts/basiercircle-mediumitalic-webfont.woff2") format("woff2");
  font-weight: normal;
  font-style: italic;
  font-display: fallback;
}

@font-face {
  font-family: "Basier Circle";
  src: url("../fonts/basiercircle-bold-webfont.woff2") format("woff2");
  font-weight: 700;
  font-style: normal;
  font-display: fallback;
}